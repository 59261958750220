<template>
  <div class="head" :class="{'pageNum2' : pageNum.pageNum == 2 && $route.path == '/home'}">
    <div class="title">
      <div class="title-img">
        <div class="title-logo-icon">
          <img src="../../../assets/images/smartedu_logo.png" @click="toSmartEdu()" width="110">
        </div>
        <div class="title-logo">
          <img src="../../../assets/images/smartedu_titlelogo.png" @click="toSmartEduJl()" width="316px">
        </div>

      </div>
    </div>
    <div class="header">
      <img class="head-logo" src="../../../assets/images/jl_smart_logo.png" width="954px">
      <div class="head-operate">
        <!-- 搜索 -->
        <div class="operate-search">
          <input type="text" v-model="searchKey" class="search-input" placeholder="搜索感兴趣的课程" />
          <img src="../../../assets/images/search@2x.png" class="search-icon" @click="handleLinkToSearch">
        </div>

        <!-- 登录 -->
        <div v-if="!userInfo && !isTrain" class="operate-login">
          <div class="login-btn hover-opa" @click="handleOpenLogin('login')">登录</div>
          <div class="login-line"></div>
          <div class="login-btn hover-opa" @click="handleOpenLogin('register')">注册</div>
        </div>
        <!-- 登录成功 -->
        <div v-if="userInfo && !isTrain" class="operate-login">
          <el-dropdown @command="handleCommand" trigger="click">
            <div class="operate-login-box">
              <img v-if="!userInfo.photo" src="../../../assets/images/head-portrait@2x.png" class="login-head" alt="">
              <img v-if="userInfo.photo" :src="userInfo.photo" class="login-head" alt="">
              <div class="login-name">{{userInfo.realname}}</div>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="personal">个人中心</el-dropdown-item>
              <el-dropdown-item command="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

    </div>

    </div>
    <!-- 登录、注册 -->
    <LoginComponent v-if="showLogin" :activeTab="activeTab" @close="handleHideLogin"></LoginComponent>
    <!-- 首次登录、注册成功、支付成功、支付失败 -->
    <MsgBoxComponent v-if="showMsgBox" :activeTab="msgBoxActiveTab" @close="hideBox"></MsgBoxComponent>
    <!-- 通知 -->
    <el-dialog :visible="openNoticeVisible" title="通知" width="70%" @close="openNoticeVisible = false">
      <NoticeComponent v-if="openNoticeVisible"></NoticeComponent>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoginComponent from '../../../components/login/index.vue'
import MsgBoxComponent from '../../../components/msgBox/index.vue'
import NoticeComponent from '../../../components/notice/index.vue'
import { getToken, setStorgeItem } from '@/utils/auth'
import WtUtil from '@/utils/wtUtil'
export default {
  name: 'HeadbarSmart',
  data() {
    return {
      ability: window.wtConst.ability,
      searchKey: '',
      // shopNum: 0,
      // showLogin: false,
      // activeTab: 'login',
      showMsgBox: false,
      msgBoxActiveTab: 'firstTimeLogin',
      isTrain: false, // 是否省校报名、省校报名详情、省校报名个人中心
      openNoticeVisible: false // 打开通知
    }
  },

  computed: {
    ...mapGetters([
      'userInfo',
      'showLogin',
      'pageNum',
      'activeTab',
      'shopNum',
      'noticeNum'
    ])
  },
  components: {
    LoginComponent,
    MsgBoxComponent,
    NoticeComponent
  },
  watch: {
    $route(to, form) {
      this.handleSetIsTrain()
      if (getToken()) {
        this.$store.dispatch('setShopNum')
        this.$store.dispatch('setNoticeNum')
      }
    }
  },
  mounted() {
    this.handleSetIsTrain()
    if (getToken()) {
      this.$store.dispatch('setShopNum')
      this.$store.dispatch('setNoticeNum')
    }
  },
  methods: {
    // 检测是否省校报名模块
    handleSetIsTrain() {
      const _hash = window.location.hash.split('#')[1].split('?')[0]
      const _hideArr = ['/train/index', '/train/detail', '/personal/train']
      if (_hideArr.indexOf(_hash) > -1) {
        this.isTrain = true
      } else {
        this.isTrain = false
      }
    },
    hideBox() {
      this.showMsgBox = false
      document.documentElement.onmousewheel = event => {
        this.pd(event, this)
      }
    },
    pd: WtUtil.debounce((event, e) => {
      console.log(e)
      if (event.wheelDelta < 0 && e.$store.state.pageNum.pageNum === 1) {
        e.$store.state.pageNum.pageNum = 2
      }
      if (event.wheelDelta > 0 && document.getElementsByClassName('main-content')[0].scrollTop === 0) {
        e.$store.state.pageNum.pageNum = 1
      }
    }, 300),
    handleBackHome() {
      this.$router.push({ path: '/home' })
    },
    // 退出登录
    handleCommand(e) {
      if (e === 'logout') {
        this.$store.dispatch('logout_smart')
      }
      if (e === 'personal') {
        setStorgeItem('personalTab', 'account')
        this.$router.push({
          path: '/personal/index'
        })
      }
    },
    handleLinkToShop() {
      if (getToken()) {
        this.$router.push({
          path: '/shoppingCart/index'
        })
      } else {
        this.$store.dispatch('setActiveTab', 'login')
        this.$store.dispatch('setShowLogin', true)
      }
    },
    handleOpenLogin(key) {
      this.$store.dispatch('setActiveTab', key)
      this.$store.dispatch('setShowLogin', true)
    },
    handleHideLogin(state) {
      if (state === 'gohome' && this.$route.path == '/recruitStudents/index') {
        this.$router.push('/home')
      }
      this.$store.dispatch('setShowLogin', false)
      // state 1 首次登录成功 2 登录成功 3 注册成功
      if (state * 1 === 1) {
        this.msgBoxActiveTab = 'firstTimeLogin'
        this.showMsgBox = true
      } else if (state * 1 === 2) {
      } else if (state * 1 === 3) {
        this.msgBoxActiveTab = 'registerSuc'
        this.showMsgBox = true
      }
    },
    // 搜索
    handleLinkToSearch() {
      if (!this.searchKey || this.searchKey.trim() === '') {
        return this.$message.warning('请输入课程名称')
      } else {
        this.$router.push({
          path: '/search/index',
          query: {
            title: this.searchKey
          }
        })
      }
    },
    handleOpenNotice() {
      if (getToken()) {
        // 打开通知
        this.openNoticeVisible = true
      } else {
        this.$store.dispatch('setActiveTab', 'login')
        this.$store.dispatch('setShowLogin', true)
      }
    },
    toSmartEdu() {
      window.location.href='https://www.smartedu.cn'
    },
    toSmartEduJl() {
      window.location.href='https://www.jl.smartedu.cn/'
    }
  }
}
</script>

<style lang="scss" scoped>

.head{
  width: 100%;
  min-width: 1440px;
  &.pageNum2{
    position: fixed;
    top: 0;
    z-index: 1;
    background-color: #fff;
  }
  .header {
    height: 108px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
    background-color: #007eff;
  }
  .title {
    background-color: #0356cb;
    height: 124px;
    width: 100%;
  }
  .title-logo{
    display: inline-block;
    height: 124px;
    position: absolute;
  }
  .title-logo img{
    margin-top: 27px;
    margin-left: 87px;
    cursor: pointer;
  }
  .title-logo-icon{
    display: inline-block;
    height: 124px;
  }
  .title-logo-icon img{
    margin-top: 20px;
    margin-left: 77px;
    cursor: pointer;
  }
  .title-img {
    margin: 0 auto;
    background-image: url('../../../assets/images/smartedu_title_nologo.jpg');
    background-size: auto 100%;
    background-repeat: no-repeat;
    height: 124px;
  }
  .head-logo{
    cursor: pointer;
  }
  .head-operate{
    display: flex;
    align-items: center;
    .operate-search{
      background-color: #ffffff;
      width: 280px;
      height: 49px;
      border: 1px solid #7F7F7F;
      border-radius: 49px;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
      .search-input{
        width: 210px;
        height: 49px;
        line-height: 49px;
        font-size: 24px;
      }
      .search-icon{
        width: 24px;
        height: 24px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
    .operate-shop{
      margin-left: 26px;
      width: 201px;
      height: 49px;
      border: 2px solid #9F0000;
      border-radius: 49px;
      padding: 0 14px 0 26px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .shop-icon-box{
        width: 34px;
        height: 34px;
        position: relative;
        .shop-icon{
          width: 34px;
          height: 34px;
        }
        .shop-num{
          width: 22px;
          height: 22px;
          text-align: center;
          line-height: 22px;
          font-size: 14px;
          font-weight: bold;
          color: #FFFFFF;
          border-radius: 50px;
          background-color: #9F0000;
          position: absolute;
          top: -5px;
          left: -10px;
        }
      }
      .shop-text{
        font-size: 24px;
        font-weight: bold;
        color: #BA0400;
      }
    }
    .operate-notice{
      margin-left: 26px;
      width: 49px;
      height: 49px;
      border: 2px solid #9F0000;
      border-radius: 49px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .notice-icon{
        width: 32px;
        height: 32px;
      }
      .notice-num{
        width: 22px;
        height: 22px;
        text-align: center;
        line-height: 22px;
        font-size: 14px;
        font-weight: bold;
        color: #FFFFFF;
        border-radius: 50px;
        background-color: #9F0000;
        position: absolute;
        top: -5px;
        left: -10px;
      }
    }
    .operate-login{
      margin-left: 33px;
      display: flex;
      align-items: center;
      .operate-login-box{
        display: flex;
        align-items: center;
      }
      .login-btn{
        font-size: 24px;
        color: #ffffff;
        cursor: pointer;
      }
      .login-line{
        width: 2px;
        height: 35px;
        margin: 0 10px;
        background-color: #ffffff;
      }
      .login-head{
        width: 63px;
        height: 63px;
        border-radius: 63px;
        margin-right: 10px;
      }
      .login-name{
        font-size: 24px;
        font-weight: bold;
        color: #ffffff;
      }
    }
    .operate-back{
      font-size: 30px;
      color: #585858;
      margin-left: 60px;
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
.operate-login{
  .el-dropdown{
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
.head{
  .el-dialog{
    border-radius: 10px;
    overflow: hidden;
    .el-dialog__header{
      background-color: #E9E9E9;
      .el-dialog__title{
        font-size: 30px;
      }
      .el-dialog__headerbtn{
        font-size: 30px;
      }
    }
  }
}
</style>

<template>
  <el-dialog
    :title="title"
    :visible="visible"
    width="1200px"
    class="purchase-notes-dialog"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="closeDialog">
    <video :src="src" controls style="width: 100%" autoplay></video>
<!--    <div slot="footer" class="dialog-footer">-->
<!--      <div class="btn" @click="closeDialog">取 消</div>-->
<!--      <div class="btn" @click="confirm">确 定</div>-->
<!--    </div>-->
  </el-dialog>
</template>

<script>
export default {
  name: 'dialogVideo',
  props: ['visible', 'src','title'],
  data() {
    return {

    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    },
    confirm() {
      if (!this.checked) {
        this.$message({ message: '请同意勾选《购买须知》', type: 'warning' })
        return
      }
      this.$emit('closeDialog')
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="scss">
.purchase-notes-dialog {
  .el-dialog {
    border: 2px solid #AB2824;
  }
  .el-dialog__header {
    text-align: center;
    border-bottom: 1px solid rgba(127, 127, 127, 0.3);
    padding: 22px 0 19px;
    .el-dialog__title {
      font-size: 36px;
      color: #030000;
    }
  }

  .el-dialog__footer {
    padding: 0;
    .dialog-footer {
      display: flex;
      .btn {
        width: 50%;
        height: 67px;
        line-height: 66px;
        text-align: center;
        font-size: 30px;
        font-weight: 400;
        color: #787878;
        background-color: rgba(127, 127, 127, 0.2);
        cursor: pointer;
        &:nth-child(2) {
          color: #C50000;
          border-left: 1px solid rgba(127, 127, 127, 0.3);
        }
      }
    }
  }
}
</style>

<template>
  <div class="app-wrapper">
    <HeadbarSmart></HeadbarSmart>
    <NavbarSmart></NavbarSmart>
    <div class="main-content" :class="routerActiveList.indexOf(routerActive) > -1 ? 'main-content-index' : ''">
      <div class="main-container">
        <app-main></app-main>
      </div>
    </div>
    <FooterbarSmart></FooterbarSmart>
  </div>
</template>

<script>
import { HeadbarSmart, NavbarSmart, FooterbarSmart, AppMain } from '@/modules/layout/components'

export default {
  name: 'layout',
  data() {
    return {
      routerActive: '/smartedu/list',
      routerActiveList: [
        '/smartedu/list'
      ]
    }
  },
  components: {
    HeadbarSmart,
    NavbarSmart,
    AppMain,
    FooterbarSmart
  },
  watch: {
    $route(val, oldVal) {
      console.log(val.path)
      this.routerActive = val.path
    }
  },
  mounted() {
    this.routerActive = this.$route.path
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.app-wrapper {
  width: 100%;
  background-color: #FFFFFF;
  margin: 0 auto;
  .main-content{
    background-color: #fff;
    //height: calc(100vh);
    width: 100vw;
    overflow-y: auto;
    &.main-content-index{
      background-color: #FFFFFF;
    }
    .main-container{
      width: 1400px;
      //height: calc(100vh - 50px);
      margin: 0 auto;
    }
  }
}
</style>

<template>
  <div class="footer" :class="{'hide':pageNum.pageNum == 1 && $route.path == '/home'}">
    <div class="footer-text">
      友情链接：
      <a href="http://www.ouchn.edu.cn/" target="_blank">国家开放大学</a>
      <a href="https://lndx.edu.cn/" target="_blank">国家老年大学</a>
      <a href="https://lndx.edu.cn/publicServicePlatform " target="_blank">全国老年教育公共服务平台</a>
      <a href="https://jtjy.crtvup.com.cn/#/home" target="_blank">家庭教育平台</a>
      <a href="https://le.ouchn.cn/Event/91c6b924-7652-a237-c908-081d94bc23d3" target="_blank">终身教育平台</a>
      <a href="https://apphudonmpx6000.h5.xiaoeknow.com/p/decorate/homepage?share_user_id=u_61e2484f1a1c6_NYbd9lerqP&wework_share_customer_id=u_61e2484f1a1c6_NYbd9lerqP&entry=2&entry_type=2001" target="_blank">“乐学公益”系列课程</a>
      <a href="http://lndx.edu.cn/Pages/PublicWellfareCourse/" target="_blank">中央国家机关老年大学</a>
      <div class="footer-feedback hover-opa" @click="handleLinkToFeedback">常见问题</div>
    </div>
    <div class="footer-copyright">
      <div>国家开放大学 版权所有</div>
      <div><a href="https://beian.miit.gov.cn" target="_blank">吉ICP备2024016827号-2</a></div>
      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=22010402001081" style="text-decoration:none;height:20px;line-height:20px;"><img src="../../../assets/images/copyright.pic.jpg" /><p style="height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#FFFFFF;">吉公网安备 22010402001081号</p></a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'FooterbarSmart',
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters([
      'pageNum'
    ])
  },
  methods: {
    handleLinkToFeedback() {
      this.$router.push({
        path: '/feedback/index'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.footer{
  width: 100%;
  background-color: #424242;
  position: relative;
  font-size: 24px;
  font-weight: bold;
  color: #FFFFFF;
  &.hide{
    display: none;
  }
  .footer-text{
    height: 114px;
    line-height: 57px;
    text-align: center;
    a{
      display: inline-block;
      margin-right: 20px;
      &:last-child{
        margin-right: 0;
      }
    }
    .footer-feedback{
      position: absolute;
      top: 90px;
      right: 20px;
      cursor: pointer;
    }
  }
  .footer-copyright{
    margin: 0 auto;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    div{
      margin: 0 10px;
    }
    a{
      margin: 0 10px;
      display: flex;
      align-items: center;
      img{
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>
